import React, { useState } from 'react';
import { FaList } from 'react-icons/fa';
import { CheckListModal } from 'components';
import style from './check-list-control.module.scss';

const TEST_DATA = [
  {
    id: 1,
    text: `Распаковка (снятие упаковочной плёнки). Проверка комплектации станка согласно спецификации. Проверка выполнения мероприятий согласно технической карте.`,
    time: '',
    isChecked: false,
  },
  {
    id: 2,
    text: `Демонтаж транспортировочных креплений.`,
    time: '',
    isChecked: false,
  },
  {
    id: 3,
    text: `Удаление консервационной смазки с направляющих, рабочего стола, ШВП и телескопических кожухов.`,
    time: '',
    isChecked: false,
  },
  {
    id: 4,
    text: `Проверка, укладка кабельной среды оборудования, монтаж гибкого кабель-канала по оси Z.`,
    time: '',
    isChecked: false,
  },
  {
    id: 5,
    text: `Предварительная выверка стола по уровню.`,
    time: '',
    isChecked: false,
  },
  {
    id: 6,
    text: `Монтаж электродвигателя на посадочное место верхней опоры оси Z, подключение питания электродвигателя.`,
    time: '',
    isChecked: false,
  },
  {
    id: 7,
    text: `Протяжка клемм электрошкафа.`,
    time: '',
    isChecked: false,
  },
  {
    id: 8,
    text: `Пробный пуск станка, проверка фазировки. Проверка работы кондиционера охлаждения шпиндельной бабки.`,
    time: '',
    isChecked: false,
  },
  {
    id: 9,
    text: `Проверка и настройка смазки. Проверка дозировки масла на всех точках смазки.`,
    time: '',
    isChecked: false,
  },
  {
    id: 10,
    text: `Настройка параметров смены инструмента, нулевых и конечных точек координат станка.`,
    time: '',
    isChecked: false,
  },
  {
    id: 11,
    text: `Проверка работы инструментального магазина. Проверка работы шпиндельного узла на минимальных, средних и максимальных оборотах. Проверка работы осей X, Y, Z (проверка люфтов по трём координатам).`,
    time: '',
    isChecked: false,
  },
  {
    id: 12,
    text: `Проверка и корректировка по уровню.`,
    time: '',
    isChecked: false,
  },
  {
    id: 13,
    text: `Установка и подключение бака СОЖ, проверка правильности вращения электродвигателя(ей) насоса(ов). Выставление бака СОЖ.`,
    time: '',
    isChecked: false,
  },
  {
    id: 14,
    text: `Заливка и проверка подачи СОЖ. Проверка отсутствия течи СОЖ из стыков кожухов.`,
    time: '',
    isChecked: false,
  },
  {
    id: 15,
    text: `Тестирование работоспособности основных узлов оборудования (в течение 4 часов).`,
    time: '',
    isChecked: false,
  },
  {
    id: 16,
    text: `Проверка работы дополнительных функций станка в ручном и автоматическом режимах (предусмотренные договором поставки).`,
    time: '',
    isChecked: false,
  },
  {
    id: 17,
    text: `Сдача станка по утверждённому в договоре протоколу (либо этот пункт раскрыть, либо прикрепить отдельный документ).`,
    time: '',
    isChecked: false,
  },
  {
    id: 18,
    text: `Подготовка и передача станка в эксплуатацию (придать станку товарный внешний вид).`,
    time: '',
    isChecked: false,
  },
  {
    id: 19,
    text: 'Проведение инструктажа по обслуживанию оборудования.',
    time: '',
    isChecked: false,
  },
  {
    id: 20,
    text: `Сделать полный бэкап станка в случаях, если:\nа) были изменены параметры, логика или какие-либо настройки станка;\nб) станок без ППП.`,
    time: '',
    isChecked: false,
  },
  { id: 21, text: 'Подписание документов.', time: '', isChecked: false },
];

type CheckListItem = {
  id: number;
  text: string;
  time: string;
  isChecked: boolean;
};

export const CheckListControl = () => {
  //TODO: состояние будет внутрри модалке
  const [checkList, setCheckList] = useState<CheckListItem[]>(TEST_DATA);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleToggleOpen = () => setIsOpenModal(state => !state);
  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <>
      <button className={style.button} onClick={handleToggleOpen}>
        <FaList color='black' fontSize={24} />
      </button>
      {isOpenModal && (
        <CheckListModal
          title='ЧЕК ЛИСТ по ПНР'
          checkList={checkList}
          setCheckList={setCheckList}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};
