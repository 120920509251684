import { fetcher, Method, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

export type CreateUserBody = {
  username: string;
  role: string;
};

export type CreateUserResponse = User & {
  password: string;
};

const createUser = async (path: string, { arg }: { arg: CreateUserBody }) =>
  fetcher<CreateUserResponse>({ path, method: Method.POST, body: arg });

export const useCreateUser = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(USER, createUser, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
