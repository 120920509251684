import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';
import { SelectDateAndTime } from 'components/select-date-and-time';
import { Modal } from 'components/ui-kit';
import style from './select-date-modal.module.scss';

type SelectDateModalProps = {
  onClose: () => void;
};
export const SelectDateModal = ({ onClose }: SelectDateModalProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  return (
    <Modal onClose={onClose}>
      <SelectDateAndTime
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
      />
      <div className={style.buttons}>
        <Button color='error' onClick={onClose}>
          Закрыть
        </Button>
        <Button variant='contained' color='success'>
          Добавить
        </Button>
      </div>
    </Modal>
  );
};
