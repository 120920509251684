import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Button, TableBody } from '@mui/material';
import { PrivateCabinetHeader, SelectMembersModal, SelectDateModal, Tabs } from 'components';
import { HOME_DISPATCHER_CONFERENCE_INFPRMATION, QUERY } from 'constants/path';
import style from './home-dispatcher.module.scss';

const TEST_DATA = [
  {
    id: 1,
    date: null,
    topic: 'Тема 1 1 1 1 1',
    place: 'Завод Певый №8',
    members: ['Участник 1', 'Участник 2', 'Участник 3', 'Участник 4', 'Участник 5', 'Участник 6', 'Участник 7'],
    status: 'access',
  },
  { id: 2, date: '22.11.2024', topic: 'Тема 1 1 1 1 1', place: 'Завод Певый №8', members: [], status: 'access' },
  { id: 3, date: '22.11.2024', topic: 'Тема 1 1 1 1 1', place: 'Завод Певый №8', members: [], status: 'access' },
  { id: 4, date: '22.11.2024', topic: 'Тема 1 1 1 1 1', place: 'Завод Певый №8', members: [], status: 'access' },
];

const TABS_DATA = [
  { id: 'active', label: 'Активные' },
  { id: 'archive', label: 'Архив' },
];

export const HomeDispatcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const firstPageType = searchParams.get(QUERY.type);
  const [pageType, setPageType] = useState(firstPageType || TABS_DATA[0].id);
  const [isOpenSelectMemberModal, setIsOpenSelectMemberModal] = useState(false);
  const [isOpenSelectDateModal, setIsOpenSelectDateModal] = useState(false);

  useEffect(() => {
    setSearchParams({ [QUERY.type]: pageType });
  }, [pageType, setSearchParams]);

  return (
    <>
      <PrivateCabinetHeader title='Встречи' />
      <div className={style.page}>
        <Tabs classNames={{ container: style.tabs }} data={TABS_DATA} activeId={pageType} onChange={setPageType} />
        <TableContainer className={style.table} component={Paper}>
          <Table sx={{ minWidth: 1100 }}>
            <TableHead>
              <TableRow>
                <TableCell>Дата и время</TableCell>
                <TableCell>Тема</TableCell>
                <TableCell>Место</TableCell>
                <TableCell>Участники</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TEST_DATA.map(({ id, date, topic, place, members, status }) => {
                return (
                  <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {date ?? (
                        <Button size='small' onClick={() => setIsOpenSelectDateModal(true)}>
                          Назначить
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>{topic}</TableCell>
                    <TableCell>{place}</TableCell>
                    <TableCell>
                      <div>
                        <div>{members.toString()}</div>
                        <div style={{ marginTop: '4px' }}>
                          <Button size='small' startIcon={<Add />} onClick={() => setIsOpenSelectMemberModal(true)}>
                            Добавить участников
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>
                      <Button size='small' color='error'>
                        Отменить
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button size='small' component={Link} to={`${HOME_DISPATCHER_CONFERENCE_INFPRMATION}/${id}`}>
                        Подробнее
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button size='small'>Подключиться</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {isOpenSelectMemberModal && <SelectMembersModal onClose={() => setIsOpenSelectMemberModal(false)} />}
      {isOpenSelectDateModal && <SelectDateModal onClose={() => setIsOpenSelectDateModal(false)} />}
    </>
  );
};
