import { fetcher, Method, USER } from 'api';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { User } from 'types';

type ChangeUserParams = {
  id: string;
  body: {
    is_active: boolean;
    role: string;
  };
};

type ChangeUserResponse = User;

const changeUser = (path: string, { arg: { id, body } }: { arg: ChangeUserParams }) =>
  fetcher<ChangeUserResponse>({ path: `${path}${id}/`, method: Method.PATCH, body });

export const useChangeUser = () => {
  const { mutate } = useSWRConfig();

  return useSWRMutation(USER, changeUser, {
    onSuccess: () => {
      mutate(key => typeof key === 'string' && key.startsWith(USER));
    },
  });
};
