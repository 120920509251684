import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { CloudUpload, Add, Delete } from '@mui/icons-material';
import { Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { PrivateCabinetHeader, SelectDateAndTime, SelectMembersModal } from 'components';
import { PRIVATE_CABINET } from 'constants/path';
import style from './сonference-information.module.scss';

enum TypeExpert {
  ElectronEngineer = 'electron-engineer',
  Mechanic = 'mechanic',
  Someone = 'someone',
}

enum TypeTime {
  RightNow = 'right-now',
  Today = 'today',
  ThreeDays = 'three-days',
  SelectDate = 'select-date',
}

type TFile = { id: number; file: File };

export const ConferenceInformation = () => {
  const navigate = useNavigate();
  const [equipmentName, setEquipmentName] = useState('');
  const [description, setDescription] = useState('');
  const [typeExpert, setTypeExpert] = useState<TypeExpert | null>(null);
  const [typeTime, setTypeTime] = useState<TypeTime | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [files, setFiles] = useState<TFile[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      setStartDate(null);
      setEndDate(null);
      setStartTime(null);
      setEndTime(null);
    };
  }, [typeTime]);

  const handleBack = () => {
    navigate(PRIVATE_CABINET);
  };

  const handleChangeTypeExpert = (value: string) => {
    setTypeExpert(value as TypeExpert);
  };

  const handleSelectTypeTime = (value: string) => {
    setTypeTime(value as TypeTime);
  };

  const handleChangeFiles: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.currentTarget;
    if (!files) return;
    const filesArr: TFile[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      if (file) {
        filesArr.push({ id: Math.random(), file });
      }
    }
    setFiles(filesArr);
  };

  /* const filesListDisplay = useMemo(() => ) */

  return (
    <>
      <PrivateCabinetHeader title='Новая конференция' onBack={handleBack} />
      <div className={style.content}>
        <TextField
          className={cn(style.item, style.item_fullWidth)}
          label='Серийный номер станка'
          value={equipmentName}
          onChange={e => setEquipmentName(e.currentTarget.value)}
        />
        <TextField
          className={cn(style.item, style.item_fullWidth)}
          label='Описание проблемы'
          multiline
          minRows={3}
          maxRows={5}
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
        />
        <div className={style.item}>
          <div className={style.label}>Файлы</div>
          <Button component='label' role={undefined} variant='contained' tabIndex={-1} startIcon={<CloudUpload />}>
            Выбрать файл
            <input style={{ display: 'none' }} type='file' multiple onChange={handleChangeFiles} />
          </Button>
          {!!files.length && (
            <div className={style.filesBlock}>
              {files.map(({ id, file }) => (
                <div key={id} className={style.fileItem}>
                  <IconButton
                    size='small'
                    onClick={() => setFiles(state => state.filter(({ id: file_id }) => file_id !== id))}
                  >
                    <Delete color='error' />
                  </IconButton>
                  <div className={style.name}>{file.name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={style.item}>
          <div className={style.label}>Тип необходимого эксперта</div>
          <RadioGroup
            aria-labelledby='type-expert-label'
            name='type-expert'
            value={typeExpert}
            onChange={e => handleChangeTypeExpert(e.currentTarget.value)}
          >
            <FormControlLabel value={TypeExpert.ElectronEngineer} control={<Radio />} label='Эксперт электронщик' />
            <FormControlLabel value={TypeExpert.Mechanic} control={<Radio />} label='Эксперт механик' />
            <FormControlLabel value={TypeExpert.Someone} control={<Radio />} label='Не знаю какой эксперт' />
          </RadioGroup>
        </div>
        <div className={style.item}>
          <div className={style.label}>Желаемое время для техподдержки</div>
          <RadioGroup
            aria-labelledby='type-time-label'
            name='type-time'
            value={typeTime}
            onChange={e => handleSelectTypeTime(e.currentTarget.value)}
          >
            <FormControlLabel value={TypeTime.RightNow} control={<Radio />} label='Горит - сейчас' />
            <FormControlLabel value={TypeTime.Today} control={<Radio />} label='Сегодня' />
            <FormControlLabel value={TypeTime.ThreeDays} control={<Radio />} label='В течении трех дней' />
            <FormControlLabel value={TypeTime.SelectDate} control={<Radio />} label='Выберите дату' />
          </RadioGroup>
        </div>
        {typeTime && typeTime !== TypeTime.RightNow && (
          <>
            <SelectDateAndTime
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
              isVisibleDate={typeTime === TypeTime.SelectDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
            />
          </>
        )}
        <div className={style.item}>
          <div className={style.label}>Участники</div>
          <Button variant='contained' startIcon={<Add />} onClick={() => setIsOpenModal(true)}>
            Добавить участников
          </Button>
        </div>
      </div>
      {isOpenModal && <SelectMembersModal onClose={() => setIsOpenModal(false)} />}
    </>
  );
};
