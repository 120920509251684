export const MODALS_SLICE = {
  'add-headset-modal': {
    successMessage: 'Kulaklık başarıyla oluşturuldu',
    title: 'Kulaklık Ekle',
    nameLabel: 'İsim',
    namePlaceholder: 'İsim giriniz',
    idPlaceholder: 'ID giriniz',
    versionLabel: 'Versiyon',
    versionPlaceholder: 'Versiyon seçiniz',
    keyLabel: 'Anahtar',
    keyPlaceholder: 'Anahtar giriniz',
    close: 'Kapat',
    add: 'Ekle',
  },
  'add-user-modal': {
    successMessage: 'Kullanıcı başarıyla oluşturuldu',
    title: 'Kullanıcı Ekle',
    loginLabel: 'Giriş',
    loginPlaceholder: 'Giriş yapınız',
    roleLabel: 'Rol',
    rolePlaceholder: 'Rol yapınız',
    expert: "uzman",
    engineer: "mühendis",
    dispatcher: "sevk memuru",
    observer: "gözlemci",
    close: 'Kapat',
    add: 'Ekle',
  },
  'change-headset-modal': {
    successMessage: 'Kulaklık verileri başarıyla değiştirildi',
    title: 'Kulaklığı Değiştir',
    nameLabel: 'İsim',
    namePlaceholder: 'İsim giriniz',
    idPlaceholder: 'ID giriniz',
    versionLabel: 'Versiyon',
    versionPlaceholder: 'Versiyon seçiniz',
    keyLabel: 'Anahtar',
    keyPlaceholder: 'Anahtar giriniz',
    close: 'Kapat',
    change: 'Değiştir',
    delete: 'Kulaklığı Sil',
  },
  'change-user-modal': {
    userSuccessMessage: 'Kullanıcı verileri başarıyla değiştirildi',
    headsetSuccessMessage: 'Kulaklık başarıyla oluşturuldu',
    title: 'Kullanıcı {{username}}',
    hasHeadset: 'Akıllı telefon-kulaklık var',
    createHeadset: 'Akıllı telefon-kulaklık oluştur',
    roleLabel: 'Rol',
    rolePlaceholder: 'Rol yapınız',
    expert: "uzman",
    engineer: "mühendis",
    dispatcher: "sevk memuru",
    observer: "gözlemci",
    statusesLabel: 'Kullanıcı durumu:',
    statuses: {
      active: 'Aktif',
      notActive: 'Deaktive',
    },
    close: 'Kapat',
    save: 'Kaydet',
  },
  'check-list-modal': {
    close: 'Kapat',
  },
  'confirm-update-user-password-modal': {
    title: '{{username}} kullanıcısının şifresini güncellemek istediğinizden emin misiniz?',
    update: 'Güncelle',
    cancel: 'İptal',
  },
  'confirm-user-registration': {
    successMessage: 'Kayıt başarıyla onaylandı',
    title: 'Kayıt Onayı',
    codeLabel: 'Mektuptan kod',
    codePlaceholder: 'Kodu giriniz',
    close: 'Kapat',
    confirm: 'Onayla',
  },
  'delete-headset-modal': {
    successMessage: 'Kulaklık başarıyla silindi',
    title: 'Kulaklığı Sil',
    discription: '{{name}} kulaklığını silmek istediğinizden emin misiniz?',
    close: 'Kapat',
    delete: 'Sil',
  },
  'edit-snapshot-modal': {
    successMessade: 'Başarıyla kaydedildi',
    errorMessade: 'Değişiklikler kaydedilemedi. {{error}}',
    title: 'Ekran Görüntüsü Düzenleyici',
    close: 'Editörü kapat',
    save: 'Değişiklikleri kaydet',
    color: {
      red: 'Kırmızı',
      blue: 'Mavi',
      black: 'Siyah',
      green: 'Yeşil',
      pink: 'Pembe',
    },
  },
  'exit-video-room': {
    finish: 'Bitir',
    pause: 'Duraklat',
    cancel: 'İptal',
  },
  'new-user-information-modal': {
    title: 'Yeni kullanıcı oluşturuldu',
    login: 'Giriş:',
    password: 'Şifre:',
    close: 'Kapat',
  },
  'photo-modal': {
    close: 'Kapat',
  },
  'video-modal': {
    close: 'Kapat',
  },
  'update-user-password-modal': {
    successMessage: 'Şifre başarıyla güncellendi',
    title: 'Şifre Güncellendi',
    login: 'Giriş:',
    password: 'Yeni şifre:',
    close: 'Kapat',
  },
};
