import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useCreateUser } from 'api';
import { CreateUserResponse } from 'api/endpoints/user/create-user';
import { Modal, Input, DropdownSelect, Button, NewUserInformationModal } from 'components';
import style from './add-user-modal.module.scss';
import { roleDataList } from 'constants/constants';

type AddUserModalProps = {
  onClose: () => void;
};

export const AddUserModal: FC<AddUserModalProps> = ({ onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'modals.add-user-modal' });
  const { trigger: createUser } = useCreateUser();
  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false);
  const [userData, setUserData] = useState<CreateUserResponse | null>(null);
  const [login, setLogin] = useState('');
  const [role, setRole] = useState( roleDataList[0].id);

  const handleAddUser = async () => {
    try {
      const body = { username: login,  role: role };
      const res = await createUser(body);
      setUserData(res);
      setIsOpenNewUserModal(true);
      toast.success(t('successMessage'));
    } catch (e) {
      toast.error(`${(e as { message: string }).message}`);
      console.error(e);
    }
  };

  const handleCloseNewUserModal = () => {
    setIsOpenNewUserModal(false);
    onClose();
  };

  const isHidden = isOpenNewUserModal;
  const isDisabled = !login;

  return (
    <>
      <Modal title={t('title')} isHidden={isHidden} onClose={onClose}>
        <div className={style.content}>
          <Input
            classNames={{ block: style.item }}
            label={t('loginLabel')}
            placeholder={t('loginPlaceholder')}
            value={login}
            onChange={setLogin}
          />
          <DropdownSelect
            classNames={{ block: style.item }}
            lable={t('roleLabel')}
            placeholder={t('rolePlaceholder')}
            value={role}
            onChange={setRole}
            dataList={roleDataList.map(item => ({ ...item, label: t(item.label) }))}
          />
          <div className={style.buttons}>
            <Button classNames={{ button: cn(style.button, style.button__red) }} label={t('close')} onClick={onClose} />
            <Button
              classNames={{ button: style.button }}
              label={t('add')}
              isDisabled={isDisabled}
              onClick={handleAddUser}
            />
          </div>
        </div>
      </Modal>
      {isOpenNewUserModal && (
        <NewUserInformationModal
          login={userData?.username ?? ''}
          password={userData?.password ?? ''}
          onClose={handleCloseNewUserModal}
        />
      )}
    </>
  );
};
