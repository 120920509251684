import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetcher } from 'api';
import 'dayjs/locale/ru';
import { SWRConfig } from 'swr';
import { Provider } from 'components';
import { AppRoutes } from 'routes';
import './i18n/config';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher,
        provider: () => new Map(),
        onError: (error, key) => {
          if (error.status === 401) {
            /* 
              console.log(response);
    removeAuthorizationToken();

    //TODO: придумать более красивое решение, без перезагрузки проекта
    const { protocol, host } = window.location;
    window.location.href = `${protocol}//${host}`;
    //
            */
          }
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <Provider>
          <ToastContainer autoClose={5000} />
          <AppRoutes />
        </Provider>
      </LocalizationProvider>
    </SWRConfig>
  </React.StrictMode>,
);
