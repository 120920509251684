import { useMemo, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { Modal } from 'components/ui-kit';
import style from './select-members-modal.module.scss';

const TEST_NAMES = [
  'Александр',
  'Сергей',
  'Дмитрий',
  'Андрей',
  'Алексей',
  'Михаил',
  'Иван',
  'Владимир',
  'Николай',
  'Евгений',
  'Павел',
  'Роман',
  'Максим',
  'Игорь',
  'Виктор',
  'Юрий',
  'Анатолий',
  'Виталий',
  'Вячеслав',
  'Валерий',
  'Олег',
  'Владислав',
  'Константин',
  'Геннадий',
  'Леонид',
  'Никита',
  'Фёдор',
  'Георгий',
  'Аркадий',
  'Эдуард',
  'Степан',
  'Артур',
  'Борис',
  'Григорий',
  'Тимур',
  'Ярослав',
  'Ростислав',
  'Всеволод',
  'Егор',
  'Станислав',
  'Руслан',
  'Денис',
  'Макар',
  'Арсен',
  'Кирилл',
  'Тимофей',
  'Пётр',
  'Филипп',
  'Лев',
  'Валентин',
  'Захар',
  'Тарас',
  'Семён',
  'Эмиль',
  'Даниил',
  'Глеб',
  'Савва',
  'Вадим',
  'Платон',
  'Эрик',
  'Валерьян',
  'Силантий',
  'Прокопий',
  'Фаддей',
  'Прохор',
  'Фома',
  'Аверкий',
  'Амвросий',
  'Иннокентий',
  'Кондрат',
  'Трофим',
  'Поликарп',
  'Клавдий',
  'Мирон',
  'Игнатий',
  'Соломон',
  'Наум',
  'Изяслав',
  'Святослав',
  'Мстислав',
  'Ратибор',
  'Всеслав',
  'Ян',
  'Добрыня',
  'Милослав',
  'Лука',
  'Панкратий',
  'Филимон',
  'Юлиан',
  'Агафон',
  'Тихон',
  'Парфений',
  'Порфирий',
  'Дорофей',
  'Савватий',
  'Емельян',
  'Вениамин',
  'Зосима',
  'Агапит',
  'Ксенофонт',
  'Нифонт',
  'Онисим',
  'Остап',
  'Рюрик',
];

type SelectMembersModalProps = {
  onClose: () => void;
};
export const SelectMembersModal = ({ onClose }: SelectMembersModalProps) => {
  const [search, setSearch] = useState('');
  const [selectMembers, setSelectMembers] = useState<string[]>([]);

  const resFackeFetch = useMemo(() => {
    if (search) return TEST_NAMES.filter(item => item.includes(search));
    return [];
  }, [search]);

  return (
    <Modal title='Выберите участников' onClose={onClose}>
      <div className={style.content}>
        <TextField
          label='Поиск сотрудника'
          fullWidth
          type='search'
          value={search}
          onChange={e => setSearch(e.currentTarget.value)}
        />
        <div className={style.members}>
          {resFackeFetch.map(item => (
            <div key={item} onClick={() => setSelectMembers(state => [...state, item])}>
              {item}
            </div>
          ))}
        </div>
        {!!selectMembers.length && (
          <>
            <div>Выбранные участнки</div>
            <div className={style.selectMembers}>
              {selectMembers.map(item => (
                <div
                  key={item}
                  onClick={() => setSelectMembers(state => state.filter(selectMember => selectMember !== item))}
                >
                  {item}
                </div>
              ))}
            </div>
          </>
        )}
        <div className={style.buttons}>
          <Button color='error' onClick={onClose}>
            Закрыть
          </Button>
          <Button variant='contained' color='success' disabled={!selectMembers.length}>
            Добавить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
