import { useMemo } from 'react';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import style from './select-date-and-time.module.scss';

const formatDate = (date: Dayjs | null) => {
  if (!date) return '';
  return date.format('DD.MM.YYYY');
};

const formatTime = (date: Dayjs | null) => {
  if (!date) return '';
  return date.format('HH:mm');
};

type SelectDateAndTimeProps = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  isVisibleDate?: boolean;
  isVisibleTime?: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setStartTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setEndTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
};
export const SelectDateAndTime = ({
  startDate,
  endDate,
  startTime,
  endTime,
  isVisibleDate = true,
  isVisibleTime = true,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
}: SelectDateAndTimeProps) => {
  const dateString = useMemo(() => {
    if (!startDate || !endDate) return;
    let date = `Желаемое время: с ${formatDate(startDate)} до ${formatDate(endDate)}`;

    if (startTime && endTime) {
      let time = ` в ${formatTime(startTime)}-${formatTime(endTime)} часов`;
      date += time;
    }

    return date;
  }, [startDate, endDate, startTime, endTime]);

  return (
    <>
      {isVisibleDate && (
        <div className={style.item}>
          Дата
          <div className={style.date}>
            <DatePicker label='От' value={startDate} onChange={setStartDate} />
            <div className={style.line}>-</div>
            <DatePicker label='До' value={endDate} onChange={setEndDate} />
          </div>
        </div>
      )}
      {isVisibleTime && (
        <div className={style.item}>
          Время
          <div className={style.date}>
            <TimePicker label='От' value={startTime} onChange={setStartTime} />
            <div className={style.line}>-</div>
            <TimePicker label='До' value={endTime} onChange={setEndTime} />
          </div>
        </div>
      )}
      {dateString && (
        <div className={style.item} style={{ color: 'orange' }}>
          {dateString}
        </div>
      )}
    </>
  );
};
