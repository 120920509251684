import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  PrivateCabinet,
  Meeting,
  HeadsetDetails,
  FinishMeeting,
  ConfirmRegistration,
  ConfirmPasswordReset,
  HomeDispatcher,
  ConferenceInformation,
  DispatcherConferenceInformation,
} from 'pages';
import { PrivateCabinetLayout } from 'components';
import {
  PRIVATE_CABINET,
  MEETING,
  FINISH_MEETING,
  HEADSET_DETAILS,
  CONFIRM_REGISTRATION,
  CONFIRM_RESET_PASSWORD,
  HOME_DISPATCHER,
  CONFERENCE_INFORMATION,
  HOME_DISPATCHER_CONFERENCE_INFPRMATION,
} from 'constants/path';

export const PrivateRouter = () => {
  return (
    <Routes>
      <Route path='*' element={<Navigate to={PRIVATE_CABINET} />} />
      <Route path={PRIVATE_CABINET} element={<PrivateCabinetLayout />}>
        {/* TODO: осущетсвлять редирект на нужную страницу по роли */}
        <Route path={PRIVATE_CABINET} element={<PrivateCabinet />} />
        {/* Диспетчер */}
        <Route path={HOME_DISPATCHER} element={<HomeDispatcher />} />
        <Route path={`${HOME_DISPATCHER_CONFERENCE_INFPRMATION}/:id`} element={<DispatcherConferenceInformation />} />
        {/*  */}
        {/*  */}
        <Route path={`${MEETING}`} element={<Meeting />} />
        <Route path={`${FINISH_MEETING}`} element={<FinishMeeting />} />
        <Route path={MEETING} element={<Meeting />} />
        <Route path={FINISH_MEETING} element={<FinishMeeting />} />
        <Route path={`${HEADSET_DETAILS}/:id`} element={<HeadsetDetails />} />
        <Route path={`${CONFERENCE_INFORMATION}/:id`} element={<ConferenceInformation />} />
      </Route>
      <Route path={`${CONFIRM_REGISTRATION}/:code`} element={<ConfirmRegistration />} />
      <Route path={`${CONFIRM_RESET_PASSWORD}/:code`} element={<ConfirmPasswordReset />} />
    </Routes>
  );
};
