import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { Add } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { PrivateCabinetHeader, SelectDateAndTime, SelectMembersModal } from 'components';
import { HOME_DISPATCHER } from 'constants/path';
import style from './dispatcher-conference-information.module.scss';

const TEST_EQUIPMENT_NAME = 'Какое-то название';
const TEST_DISCRIPTION =
  'Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы Описание прибломы ';
const TEST_FILES = [{ id: 'file_1' }, { id: 'file_2' }, { id: 'file_3' }, { id: 'file_4' }];
const TEST_TYPE_EXPERT = 'electron-engineer';
const TEST_TYPE_TIME = 'today';

enum TypeExpert {
  ElectronEngineer = 'electron-engineer',
  Mechanic = 'mechanic',
  Someone = 'someone',
}

enum TypeTime {
  RightNow = 'right-now',
  Today = 'today',
  ThreeDays = 'three-days',
  SelectDate = 'select-date',
}
const OPTIONS_TYPE_EXPERT = {
  [TypeExpert.ElectronEngineer]: 'Эксперт электронщик',
  [TypeExpert.Mechanic]: 'Эксперт механик',
  [TypeExpert.Someone]: 'Не знаю какой эксперт',
};
const OPTIONS_TYPE_TIME = {
  [TypeTime.RightNow]: 'Горит - сейчас',
  [TypeTime.Today]: 'Сегодня',
  [TypeTime.ThreeDays]: 'В течении трех дней',
  [TypeTime.SelectDate]: 'Выберите дату',
};
export const DispatcherConferenceInformation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleBack = () => {
    navigate(HOME_DISPATCHER);
  };

  return (
    <>
      <PrivateCabinetHeader title={`Информация конференции ${id}`} onBack={handleBack} />
      <div className={style.content}>
        <TextField
          className={cn(style.item, style.item_fullWidth)}
          label='Серийный номер станка'
          value={TEST_EQUIPMENT_NAME}
          aria-readonly
        />
        <TextField
          className={cn(style.item, style.item_fullWidth)}
          label='Описание проблемы'
          multiline
          minRows={3}
          maxRows={5}
          value={TEST_DISCRIPTION}
          aria-readonly
        />
        {!!TEST_FILES.length && (
          <div className={style.item}>
            <div className={style.label}>Файлы</div>
            <div className={style.filesBlock}>
              {TEST_FILES.map(({ id /* file */ }) => (
                <div key={id} className={style.fileItem}>
                  <div className={style.name}>{id}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={style.item}>
          <div className={style.label}>Тип необходимого эксперта</div>
          {OPTIONS_TYPE_EXPERT[TEST_TYPE_EXPERT]}
        </div>
        <div className={style.item}>
          <div className={style.label}>Желаемое время для техподдержки</div>
          {OPTIONS_TYPE_TIME[TEST_TYPE_TIME]}
        </div>
        {/*TODO: игнора потом не будет */}
        {/* @ts-ignore */}
        {TEST_TYPE_TIME && TEST_TYPE_TIME !== TypeTime.RightNow && (
          <>
            <div className={style.label}>Выберите время</div>
            <SelectDateAndTime
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
              /*TODO: игнора потом не будет */
              /* @ts-ignore */
              isVisibleDate={TEST_TYPE_TIME === TypeTime.SelectDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
            />
          </>
        )}
        <div className={style.item}>
          <div className={style.label}>Участники</div>
          <Button variant='contained' startIcon={<Add />} onClick={() => setIsOpenModal(true)}>
            Добавить участников
          </Button>
        </div>
      </div>
      {isOpenModal && <SelectMembersModal onClose={() => setIsOpenModal(false)} />}
    </>
  );
};
